<template>
  <div>
    <orders />
  </div>
</template>
<script>
import Orders from "@/components/orders.vue";
export default {
  components: { Orders }
};
</script>
<style scoped></style>
