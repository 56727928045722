//import en from './en.json'
import auth from "./auth.json";
import home from "./home.json";
import businessf from "./businessf.json";
import search from "./search.json";
import network from "./network.json";
import businessowner from "./businessowner.json";
import verification from "./verification.json";
import passrecover from "./passrecover.json";
import welcome from "./welcome.json";
import dashboard from "./dashboard.json";
import profileowner from "./profileowner.json";
import Order from "./Order.json";
import myOrders from "./myOrders.json";

import memnetwork from "./memnetwork.json";

import profilefollower from "./profilefollower.json";
import about from "./about.json";
import settings from "./settings.json";
import general from "./general.json";

export const defaultLocale = "en";

export const en = {
  auth: auth,
  home: home,
  businessf: businessf,
  businessowner: businessowner,
  search: search,
  network: network,
  passrecover: passrecover,
  welcome: welcome,
  dashboard: dashboard,
  memnetwork: memnetwork,
  profilefollower: profilefollower,
  verification: verification,
  profileowner: profileowner,
  general: general,

  Order: Order,
  myOrders: myOrders,
  about: about,
  settings: settings
};
