<template>
  <div>
    <about />
  </div>
</template>
<script>
import about from "@/components/businessOwner/tabs/about.vue";
export default {
  components: { about }
};
</script>
