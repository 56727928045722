<template>
  <div>
    <CommunityMembers />
  </div>
</template>

<script>
import CommunityMembers from "../../communityMember";
export default {
  components: {
    CommunityMembers
  }
};
</script>

<style></style>
