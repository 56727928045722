import { render, staticRenderFns } from "./placesLived.vue?vue&type=template&id=48c4f094&scoped=true&"
import script from "./placesLived.vue?vue&type=script&lang=js&"
export * from "./placesLived.vue?vue&type=script&lang=js&"
import style0 from "./placesLived.vue?vue&type=style&index=0&id=48c4f094&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48c4f094",
  null
  
)

export default component.exports