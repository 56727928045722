var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.length > 0)?_c('div',[(_vm.items.length == 1)?_c('div',{staticClass:"lb"},[_c('div',{class:[
        _vm.css,
        _vm.items.length > _vm.cells ? 'lb-grid-' + _vm.cells : 'lb-grid-' + _vm.items.length
      ]},_vm._l((_vm.items),function(src,i){return _c('div',{key:i,on:{"click":function($event){return _vm.show(i)}}},[_c('img',{staticClass:"single-image",attrs:{"src":src,"alt":""}})])}),0)]):(_vm.items.length == 2)?_c('div',{staticStyle:{"position":"relative"}},[_c('b-row',{staticClass:"p-0"},_vm._l((_vm.items),function(src,i){return _c('b-col',{key:i,staticClass:"p-0",attrs:{"cols":"6"},on:{"click":function($event){return _vm.show(i)}}},[_c('img',{staticClass:"double-image pr-1",staticStyle:{"object-fit":"cover","height":"100%"},attrs:{"src":src}})])}),1)],1):(_vm.items.length == 3)?_c('div',{staticClass:"lb"},[_c('div',{staticClass:"lb-grid",class:[
        _vm.css,
        _vm.items.length > _vm.cells ? 'lb-grid-' + _vm.cells : 'lb-grid-' + _vm.items.length
      ]},_vm._l((_vm.items),function(src,i){return _c('div',{key:i,staticClass:"lb-item",style:(_vm.bg(src)),on:{"click":function($event){return _vm.show(i)}}},[(i == _vm.cells - 1 && _vm.items.length - _vm.cells > 0)?_c('span',{staticClass:"lb-more"},[_vm._v(_vm._s(_vm.items.length - _vm.cells)+"+")]):_vm._e()])}),0)]):(_vm.items.length == 4)?_c('div',{staticClass:"lb"},[_c('div',{staticClass:"lb-grid",class:[
        _vm.css,
        _vm.items.length > _vm.cells ? 'lb-grid-' + _vm.cells : 'lb-grid-' + _vm.items.length
      ]},_vm._l((_vm.items),function(src,i){return _c('div',{key:i,staticClass:"lb-item",style:(_vm.bg(src)),on:{"click":function($event){return _vm.show(i)}}},[(i == _vm.cells - 1 && _vm.items.length - _vm.cells > 0)?_c('span',{staticClass:"lb-more"},[_vm._v(_vm._s(_vm.items.length - _vm.cells)+"+")]):_vm._e()])}),0)]):(_vm.items.length == 5)?_c('div',{staticClass:"lb"},[_c('div',{staticClass:"lb-grid",class:[
        _vm.css,
        _vm.items.length > _vm.cells ? 'lb-grid-' + _vm.cells : 'lb-grid-' + _vm.items.length
      ]},_vm._l((_vm.items),function(src,i){return _c('div',{key:i,staticClass:"lb-item",style:(_vm.bg(src)),on:{"click":function($event){return _vm.show(i)}}},[(i == _vm.cells - 1 && _vm.items.length - _vm.cells > 0)?_c('span',{staticClass:"lb-more"},[_vm._v(_vm._s(_vm.items.length - _vm.cells)+"+")]):_vm._e()])}),0)]):_c('div',{staticClass:"lb-grid",class:[
      _vm.css,
      _vm.items.length > _vm.cel ? 'lb-grid-' + _vm.cel : 'lb-grid-' + _vm.items.length
    ]},_vm._l((_vm.filterItems),function(src,i){return _c('a',{key:i,staticClass:"lb-item",style:(_vm.bg(src)),attrs:{"href":_vm.items[i],"role":"link"},on:{"click":function($event){$event.preventDefault();return _vm.show(i)}}},[(i == _vm.cel - 1 && _vm.items.length - _vm.cel > 0)?_c('span',{staticClass:"lb-more"},[_vm._v(_vm._s(_vm.items.length - _vm.cel)+"+")]):_vm._e()])}),0),_c('transition',{attrs:{"enter-active-class":"animated fadeIn","leave-active-class":"animated fadeOut"}},[(_vm.index >= 0)?_c('div',{staticClass:"lb-modal"},[_c('button',{staticClass:"btn btn-outline-danger btn-sm lb-modal-close",on:{"click":_vm.close}},[_c('i',{staticClass:"fas far fa-times"})]),_c('button',{staticClass:"btn btn-outline-primary lb-modal-prev",on:{"click":_vm.prev}},[_c('i',{staticClass:"fas far fa-angle-left fa-2x"})]),_c('button',{staticClass:"btn btn-outline-primary lb-modal-next",on:{"click":_vm.next}},[_c('i',{staticClass:"fas far fa-angle-right fa-2x"})]),_c('div',{staticClass:"lb-modal-img",on:{"click":_vm.close}},[_c('img',{attrs:{"src":_vm.src}}),(_vm.loading)?_c('div',{staticClass:"spinner spinner-dots-wave"},[_c('span',{staticClass:"badge badge-primary rounded-circle w-10 h-10"},[_c('i',{staticClass:"sr-only"},[_vm._v(" ")])]),_c('span',{staticClass:"badge badge-primary rounded-circle w-10 h-10"},[_c('i',{staticClass:"sr-only"},[_vm._v(" ")])]),_c('span',{staticClass:"badge badge-primary rounded-circle w-10 h-10"},[_c('i',{staticClass:"sr-only"},[_vm._v(" ")])]),_c('span',{staticClass:"badge badge-primary rounded-circle w-10 h-10"},[_c('i',{staticClass:"sr-only"},[_vm._v(" ")])]),_c('span',{staticClass:"badge badge-primary rounded-circle w-10 h-10"},[_c('i',{staticClass:"sr-only"},[_vm._v(" ")])])]):_vm._e()])]):_vm._e()])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }