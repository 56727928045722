import { render, staticRenderFns } from "./notification.vue?vue&type=template&id=ae73874a&scoped=true&"
import script from "./notification.vue?vue&type=script&lang=js&"
export * from "./notification.vue?vue&type=script&lang=js&"
import style0 from "./notification.vue?vue&type=style&index=0&id=ae73874a&scoped=true&lang=css&"
import style1 from "./notification.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae73874a",
  null
  
)

export default component.exports