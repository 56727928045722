<template>
  <b-container>
    <b-container>
      <p class="a-text">
        {{ $t("businessf.") }}
        {{
          $t("businessf.Select_a_payment_method_for_your_bridge_africa_account")
        }}
      </p>
    </b-container>

    <b-container class="payment-type">
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          id="Paypal"
          name="paypal"
          value="paypal"
          >Paypal</b-form-radio
        >
        <br />
        <b-form-radio
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          id="Mobile Payment"
          name="mobile-payment"
          value="mobile-money"
        >
          {{ $t("businessf.Mobile_Payment") }}
        </b-form-radio>
        <br />
        <b-form-radio
          v-model="selected"
          :aria-describedby="ariaDescribedby"
          id="Credit-card"
          name="credit-card"
          value="credit-card"
          >{{ $t("businessf.Credit_Debit_card") }}</b-form-radio
        >
      </b-form-group>

      <div class="payment-image col-md-6">
        <img
          class="mb-2 img-fluid"
          src="@/assets/img/credit-cards-logos.png"
          alt="Fluid image"
        />
      </div>

      <b-container class="h-background">
        <div class="account-headnig">
          <h3 class="heading-user h3-color">4755-xxxx-xxxx</h3>
          <p class="card-name">123456789</p>
        </div>
      </b-container>

      <div class=" mb-0">
        <b-icon icon="plus" class="b-color"></b-icon>
        <b-link href="#"
          >{{ $t("businessf.Add_new_Credit_Debit_card") }}
        </b-link>
      </div>
      <br />

      <div class=" mb-0">
        <p>
          <b-icon icon="lock" class="b-color"></b-icon>
          {{ $t("businessf.Your_payment_information_is_secure") }} ,
          <b-link href="#">{{ $t("businessf.Read_more") }}</b-link>
        </p>
      </div>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "payment"
};
</script>

<style scoped>
.payment-type {
  background-color: #f7f7f7;
  padding: 4px 15px;
  text-align: left;
}

.h3-color {
  color: #000;
}

.b-color {
  color: #7952b3;
}
.tabs {
  width: 100%;
}

.account-headnig {
  padding-left: 12px;
  padding-top: 7px;
  text-align: left;
}
.h-background {
  display: flex;
  background-color: #e4e3e3;
}
.payment-image {
  margin-top: -60px;
  float: right;
}

.a-text {
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
}
.settings {
  margin-left: 90%;
  position: relative;
  top: 40px;
}

@media only screen and (max-width: 768px) {
  .settings {
    top: -5px;
    left: -20px;
  }
}
</style>
