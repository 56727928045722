<template>
  <div>
    <myoders />
  </div>
</template>

<script>
import myoders from "@/components/myOders.vue";
export default {
  components: { myoders }
};
</script>
