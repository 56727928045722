<template>
  <div>
    <Business />
  </div>
</template>

<script>
import Business from "../businessmain";
export default {
  components: {
    Business
  }
};
</script>

<style></style>
