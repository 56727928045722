import { fr } from "./fr/index.js";

import { en } from "./en/index.js";

export const defaultLocale = "en";

export const languages = {
  en,
  fr
};
