export const FIND_USER = "FIND_USER";
export const FIND_POST = "FIND_POST";
export const STACK_VALUE = "STACK_VALUE";
export const LOADING = "LOADING";

export const FIND_PROFESSION = "FIND_PROFESSION";
export const FIND_COMMUNITY = "FIND_COMMUNITY";
export const FIND_BUISNESS_POST = "FIND_BUISNESS_POST";
export const FIND_MIXED_POST = "FIND_MIXED_POST";
export const FIND_MIXED_USER = "FIND_MIXED_USER";
export const FIND_NETWORK_POST = "FIND_NETWORK_POST";
export const GET_RESULT_USER = "GET_RESULT_USER";
export const GET_RESULT_POST = "GET_RESULT_POST";
export const RESET_RESULT = "RESET_RESULT";

export const SET_CURRENT_PAGINATE_CALLBACK = "SET_CURRENT_PAGINATE_CALLBACK";
export const GET_CURRENT_PAGINATE_CALLBACK = "GET_CURRENT_PAGINATE_CALLBACK";

export const GET_CURRENT_PAGINATION_PAGE = "GET_CURRENT_PAGINATION_PAGE";
export const SET_CURRENT_PAGINATION_PAGE = "SET_CURRENT_PAGINATION_PAGE";

export const POST_KEYWORD = "POST_KEYWORD";
